<template>
  <div >
    <!-- <el-menu default-active="0" class="el-menu-demo" mode="horizontal" background-color="#545c64" text-color="#fff" active-text-color="#ffd04b">
      <el-menu-item index="0"  @click="handleSelect(0)" >人物</el-menu-item>
      <el-menu-item index="1"  @click="handleSelect(1)" >搜索</el-menu-item>
    </el-menu> -->
    <HeaderView :page_index="-1"></HeaderView>
    <el-skeleton v-if="loading" :rows="5" animated  style="text-align: left;" />
    <div v-else class="home">
      <el-container>
      <el-container>
        <el-aside width="200px">
          <text> {{info.title}}</text>
          <div style="text-align: left; margin-bottom:10px" >
            <div style="margin: 10px 0px;"><el-input v-model="content2" :autosize="{ minRows: 6, maxRows: 2000 }" type="textarea" placeholder="输入多个人物id，换行间隔" /> </div>
            <div class=""><el-button type="primary" @click="search_words(false, 'word')" size='large'>多人物搜索</el-button></div>
          </div>
          <div v-for="(bind_info, index) in info.bind_list" :key="index" class="" style="display: inline-block; width: 180px; margin: 0px; vertical-align: top; text-align: left;">
            {{ bind_info.person_info.word }}、
          </div>
        </el-aside>
        <el-container>
          <el-skeleton v-if="loading3" :rows="5" animated  style="text-align: left;" />
          <el-main  v-else style="text-align: left;">
            <div v-for="(bind_info, index) in info.bind_list" :key="index" class="" style="display: inline-block; width: 180px; margin: 6px; vertical-align: top; ">
              <div style="color:#bbb">{{ index + 1 }} {{ bind_info.desc }}</div>
              <div><OnePersonCanPull  :person_info="bind_info.person_info" tag="middle"/></div>
            </div>
            <div  v-if="!loading2" style="height:10px; background:#bbe6d6;margin:6px 0px;"></div>

            <div class="tab2">
              <el-tag  v-for="(item, index)  in group_list" :key="index" class="mx-1" type="info" effect="dark">{{item.name}} {{item.num}}</el-tag>

            <el-dropdown :split-button='true' size='small'>
              <span class="el-dropdown-link" >排序 {{person_paixu}}<el-icon class="el-icon--right"><arrow-down /></el-icon>
              </span>
              <template #dropdown>
                <el-dropdown-menu>
                  <el-dropdown-item  v-for="(paixu, index) in paixus" :key="index" ><span @click="set_paixu(paixu[1])" :class="person_paixu===paixu[1] ? 'green' : ''" >按{{paixu[0]}}</span></el-dropdown-item>
                </el-dropdown-menu>
              </template>
            </el-dropdown>
          </div>
          <el-skeleton v-if="loading2" :rows="5" animated  style="text-align: left;" />
          <div v-else>
            <!-- <OnePersonLittle :person_info="info"  v-for="(info, index) in list" :key="index"  /> -->
            <div>

              <div  v-for="(bind_info, index) in pre_list" :key="index" class="" style="display: inline-block; width: 300px; margin: 6px; ">
                <div>
                  <OnePersonCanPull :person_info="bind_info.person_info" tag="little"/>
                  <el-switch v-model=" bind_info.select"   size="small" />
                </div>
                <div style="color:#bbb">{{ bind_info.person_info.desc }}</div>

                <div>
                  <div class="rank_index">
                    <el-input-number v-model="bind_info.index" :min="1" :max="100"/>
                  </div>
                  <div class="rank_desc">
                    <el-input  v-model="bind_info.desc"  placeholder="描述"  class="input-with-select">
                      <template #append>
                        <el-button >add</el-button>
                      </template>
                    </el-input>
                  </div>
                </div>
              </div>
              <div v-if="pre_list.length" style="text-align: left; margin:10px" >
                <div class=""><el-button type="success" @click="rank_add_select_persons()" size='large'>新增/更新</el-button></div>
              </div>
            </div>

            <!-- <OnePersonLittle :person_info="info"  v-for="(info, index) in list" :key="index"  /> -->
          </div>
          </el-main>
        </el-container>
      </el-container>
    </el-container>
  </div>
</div>
</template>

<script>
import store from '@/store'
import Utils from '@/utils/utils'
import axios from 'axios'
import OnePersonCanPull from '@/components/items/OnePersonCanPull'
import HeaderView from '@/components/other/HeaderView'
import { ElLoading } from 'element-plus'

export default {
  name: 'Rank_detail_edit',
  mounted () {
    this.get_index()
  },
  components: {
    OnePersonCanPull, HeaderView
  },
  data () {
    return {
      loading: true,
      loading2: false,
      loading3: false,
      is_long: false,
      niming: store.state.niming,
      base_img: store.state.base_img,
      type_list: [],
      pre_list: [],
      // in_wheres: [['不介意', null], ['结巴', 'j'], ['pku', 'p'], ['上版本', '1'], ['都介意', 'all']],
      see_wants: [['全部', 'all'], ['type', 'type'], ['my', 'my'], ['net', 'net'], ['都不', 'none']],
      current_type_index: 0,
      current_group_index: 0,
      current_page: 1,
      zici_paixu: 'index',
      group_tag: 'my',
      person_selects: [],
      see_want: 'net',
      content2: ''
    }
  },
  methods: {
    get_index () {
      if (localStorage.getItem('search_person_paixu')) {
        this.person_paixu = localStorage.getItem('person_paixu')
      }
      axios.post(Utils.check_is_niming() ? '/rank_detail_unlogin/' : '/rank_detail/', { id: this.$route.params.id })
        .then(res => {
          const loadingInstance = ElLoading.service()
          if (res.data.code !== 200) {
            Utils.alert(res.data.msg)
            return
          }
          this.info = res.data.data.info
          this.loading = false
          loadingInstance.close()
        })
    },
    search_words (e) {
      this.loading3 = true
      this.list = []
      this.current_page = 1

      axios.post('/rank_person_search/', { rank_id: this.info.id, content: this.content2, paixu: this.person_paixu })
        .then(res => {
          if (res.data.code !== 200) {
            Utils.alert(res.data.msg)
            return
          }
          this.loading3 = false
          this.pre_list = res.data.data.list
          this.group_list = res.data.data.group_list
          // this.content = ''
          // Utils.alert_success('添加成功')
        })
    },
    add_select_persons (e) {
      console.log(this.person_selects)
    },
    refresh_quality_tab_list () {},
    set_paixu (e) {
      localStorage.setItem('search_person_paixu', e)
      this.person_paixu = e
      this.refresh_quality_tab_list()
    },
    change_is_rank (e) {
      console.log(e)
    },
    rank_add_select_persons () {
      this.loading3 = true
      var persons = []
      for (var i = 0; i < this.pre_list.length; i++) {
        var bindInfo = this.pre_list[i]
        if (bindInfo.select) {
          persons.push({ person_id: bindInfo.info.id, index: bindInfo.index, desc: bindInfo.desc })
        }
      }
      axios.post('/rank_add_select_persons/', { rank_id: this.info.id, persons: persons })
        .then(res => {
          if (res.data.code !== 200) {
            Utils.alert(res.data.msg)
            return
          }
          this.info = res.data.data.info
          this.loading3 = false
          Utils.alert_success('添加成功')
        })
    }
  }
}
</script>
<style scoped>
.tab2{
  display: block;
  text-align: left;
  padding: 30px 40px 30px 20px;
}
.tab2 .el-tag,.tab2  .el-dropdown{
  vertical-align: middle;
}
.to_sw{
  color: #fff;
  background: #67c23a;
  padding: 16px 0px;
  width: 160px;
  margin: 20px 0px 20px 40px;
  cursor:pointer;
}

.mx-1{
  margin: 0px 5px;
  cursor:pointer;
}
ul, li {
  margin: 0;
  padding: 0;
  list-style: none;
}
li{
  background: lightblue;
}
li div.border_bottom{
  border-bottom:  1px solid #999;
}
li div.border_top{
  border-top:  1px solid #999;
}
li div.border_right{
  border-right:  1px solid #999;
}
li div.border_left{
  border-left:  1px solid #999;
}
li div{
  /* vertical-align: middle; */
  /* display:table-cell; */
  /* border: 1px solid pink; */
  display: flex;
  align-items: center;
  justify-content: center;
  cursor:pointer;

}
li div.left,li div.right{
  display: inline-block;
  width: 98px;
  vertical-align: top;
}
li .heng2{
  width: 200px;
}
</style>
